import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(date: Date) {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  const weekday = date.toLocaleString('en-US', { weekday: 'short' });

  const dayOfMonth = date.getDate();

  const monthShort = date.toLocaleString('en-US', { month: 'short' });

  // Build final string: e.g. "09:33 Sun 26 Jan"
  return `${hours}:${minutes} ${weekday} ${dayOfMonth} ${monthShort}`;
}